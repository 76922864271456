<template>
  <div class="container">
    <form-builder :loading="loading" :questions="questions" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const FormBuilder = () => import(/* webpackPrefetch: true */ '@/components/extras/FormBuilder')

export default {
  name: 'preaching-form',
  components: {
    FormBuilder,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      questions: [],
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
  },
  created () {
    this.getQuestions()
  },
  methods: {
    fixData (data) {
      const definition = JSON.parse(data.definition)
      for (const q of definition) {
        q.value = ''
      }
      return definition
    },
    async getQuestions () {
      let response = null
      try {
        response = await this.$http.get('forms/latest/0')
      } catch (e) {
        console.error('Couldnt get dynamic form', e)
        if (e.request.status === 403) return
        return setTimeout(() => this.getQuestions(), 10000)
      }

      const data = this.fixData(response.data.data)
      this.questions = data.slice(0)
    },
    submit () {
      const questions = []
      for (const q of this.questions) {
        const p = { ...q, title_base: this.$t(q.title) }
        questions.push(p)
      }
      this.$emit('submit', questions.slice(0))
    },
  },
}
</script>
